import React from 'react';
import './index.css';

const NoMatch = () => {
  return (
    <div>[NO MATCH]</div>
  );
}

export default NoMatch;
